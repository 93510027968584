import { PageProps } from 'gatsby';
import React from 'react';

import { IAQSchools, Studies, StudiesCta, StudiesHeader } from 'components/CaseStudies';
import { SEO } from 'components/UI';
import seo from 'data/seo.json';

export default function CaseStudies(props: PageProps) {
	return (
		<>
			<SEO
				title={seo.studiesTitle}
				description={seo.studiesDescription}
				ogTitle={seo.studiesOgTitle}
				ogDescription={seo.studiesOgDescription}
				ogImage={seo.studiesOgImage}
			/>
			<StudiesHeader />
			<IAQSchools path={props.path} />
			<Studies />
			<StudiesCta />
		</>
	);
}
